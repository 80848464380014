import { useRouter } from 'next/router'

import { ISODate } from 'lib/toISODateFormat'

import getNextDate from '@mindfulchefuk/utils/getNextDate'
import { ShopOrderStatus, ProductType } from '@mindfulchefuk/query'
import { useGetShopOrders } from '@mindfulchefuk/features/ShopOrder/hooks/useGetShopOrders'
import { useAvailableDates } from '@mindfulchefuk/features/Operations/hooks/useAvailableDates'
import { productTypeConfig, ProductTypeConfig } from './productTypeConfig'

export function useProductTypeConfig(): ProductTypeConfig {
  const {
    query: { page },
  } = useRouter()

  return productTypeConfig[page as ProductType]
}

export function useNextAvailableProductsDate(fromDate: ISODate): ISODate {
  const { shop } = useAvailableDates()
  const { data: shopOrders = [] } = useGetShopOrders()
  const completedShopOrderDates = shopOrders
    .filter(
      (shopOrder) =>
        shopOrder.status === ShopOrderStatus.COMPLETED ||
        shopOrder.status === ShopOrderStatus.SUBMITTED
    )
    .map((shopOrder) => shopOrder.deliveryDate)

  return getNextDate({
    fromDate,
    unavailableDates: [...shop.unavailableDates, ...completedShopOrderDates],
  })
}
