import React from 'react'
import { useRouter } from 'next/router'

import { useCMSPageBanner } from '@mindfulchefuk/features/CMS/hooks/useCMSPageBanner'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { CMSPageApiPathWithBanners } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { useCMSBannerRedirect } from '@mindfulchefuk/features/CMS/hooks/useCMSBannerRedirect'
import { CMSBannerLinkWrapper } from '@mindfulchefuk/features/CMS/components/CMSBannerLinkWrapper'
import { ProductType } from '@mindfulchefuk/analytics'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const ShopPromotionalBanner = ({
  page,
}: {
  page: CMSPageApiPathWithBanners
}) => {
  const router = useRouter()
  const r2gTabActive = router.query?.page && router.query?.page !== 'recipes'
  const banner = useCMSPageBanner({
    page,
    productCategory: r2gTabActive && (router.query?.page as ProductType),
  })
  const { redirectURL } = useCMSBannerRedirect(banner?.redirectConfig)

  if (!banner || !router.isReady) return null

  return (
    <CMSBannerLinkWrapper
      href={redirectURL}
      mb={24}
      onClick={() =>
        r2gTabActive
          ? trackAnalyticsEvent({
              name: 'R2G banner clicked',
              data: {
                'banner name': banner.title,
                'campaign name': banner.campaign ? banner.campaign.title : '',
                'page source':
                  page === 'page-shop-onboarding'
                    ? 'ready to go category page'
                    : 'account ready to go category page',
                'product category': router.query?.page as ProductType,
              },
            })
          : trackAnalyticsEvent({
              name: 'choose recipes banner clicked',
              data: {
                'banner name': banner?.title,
                'campaign name': banner?.campaign ? banner?.campaign.title : '',
                'page source': 'account recipes page',
                'customer preferences': [''],
              },
            })
      }
    >
      <CMSImage
        data-testid="cms-shop-promotional-banner"
        src={banner.image.src}
        sources={banner.image.sources}
        alt={banner.image.altText}
      />
    </CMSBannerLinkWrapper>
  )
}
