import { CMSPageApiPathWithBanners } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { useCMSPage } from '@mindfulchefuk/features/CMS/hooks/useCMSPage'
import { CMSBanner } from '@mindfulchefuk/features/CMS/interfaces'
import { ProductType } from '@mindfulchefuk/query'

type TUseCMSPageBanner = {
  page: CMSPageApiPathWithBanners
  productCategory?: ProductType
}

export const useCMSPageBanner = ({
  page,
  productCategory,
}: TUseCMSPageBanner): CMSBanner => {
  const { data: cmsPage, isLoading } = useCMSPage(page)
  if (!isLoading && cmsPage?.banners) {
    // We default to the earliest banner
    let pageBanner = cmsPage.banners?.[0]

    // This tabbed page can have multiple banners
    if (page === 'page-account-delivery' && !productCategory) {
      pageBanner = cmsPage.banners.find(
        (banner) => banner.page === 'Account - Choose Recipes'
      )
    }

    if (productCategory) {
      const productCategoryBanner = cmsPage.banners.find(
        (banner) =>
          banner.page === 'Ready To Go' &&
          banner.readyToGoPageTargeting?.includes(productCategory)
      )
      const fallback = cmsPage.banners.find(
        (banner) =>
          banner.page === 'Ready To Go' && !banner.readyToGoPageTargeting
      )
      pageBanner = productCategoryBanner || fallback
    }

    return pageBanner || null
  }
  return null
}
