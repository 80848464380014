import { useEffect, useState } from 'react'

/**
 * Use if the structure of the HTML can change at all between the server and the client
 *
 * Common occurrences:
 *  - AB tests
 *  - Signed in/signed out
 *
 * @returns true if code is on client side and the client has hydrated
 */
export const useHasClientHydrated = (): boolean => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    if (!isClient) setIsClient(true)
  }, [isClient])

  return isClient
}
