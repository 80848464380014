import { useRouter } from 'next/router'

const variantQueryKey = {
  newbie: 'n',
  pro: 'p',
}

export const useDualFlowVariant = () => {
  const { query, pathname } = useRouter()

  return {
    isNewbie: pathname === '/welcome' && query?.u === variantQueryKey.newbie,
    isPro: pathname === '/welcome' && query?.u === variantQueryKey.pro,
  }
}
