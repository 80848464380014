import { addDays } from 'date-fns'
import toISODateFormat, { ISODate } from '@mindfulchefuk/lib/toISODateFormat'

interface GetNextDateParams {
  fromDate: ISODate
  unavailableDates: ISODate[]
}

export default function getNextDate({
  fromDate,
  unavailableDates,
}: GetNextDateParams): ISODate {
  let currentDate: ISODate | Date = fromDate
  let nextDate
  while (!nextDate) {
    const formattedDate = toISODateFormat(currentDate)
    const isAvailable = !unavailableDates.includes(formattedDate)

    if (isAvailable) {
      nextDate = formattedDate
    } else {
      currentDate = addDays(currentDate, 1)
    }
  }

  return nextDate
}
