import React from 'react'
import styled from '@emotion/styled'
import { Flex, Text } from '@mindfulchefuk/design-system'
import { useCMSPageBanner } from '@mindfulchefuk/features/CMS/hooks/useCMSPageBanner'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { DELIVERY_EDIT_GRACE_PERIOD } from '@mindfulchefuk/constants'

import { getDaysLeft } from '@mindfulchefuk/utils/getDaysLeft'
import { subDays } from 'date-fns'
import toISODateFormat from '@mindfulchefuk/lib/toISODateFormat'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { useCMSBannerRedirect } from '@mindfulchefuk/features/CMS/hooks/useCMSBannerRedirect'
import { CMSBannerLinkWrapper } from './CMSBannerLinkWrapper'

const ResponsiveText = styled(Text)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
`

export const CalendarPromotionalBanner = () => {
  const banner = useCMSPageBanner({ page: 'page-account-homepage' })
  const { redirectURL, analytics } = useCMSBannerRedirect(
    banner?.redirectConfig
  )
  if (!banner) return null

  const { countdown } = banner.variants
  const daysLeft = getDaysLeft(
    toISODateFormat(
      subDays(
        banner.redirectConfig?.recipeDelivery?.lastDeliveryDate,
        DELIVERY_EDIT_GRACE_PERIOD
      )
    )
  )

  return (
    <CMSBannerLinkWrapper
      href={redirectURL}
      mx={[-24, 'auto']}
      mb={8}
      display="block"
      position="relative"
      overflow="hidden"
      onClick={() =>
        trackAnalyticsEvent({
          name: 'calendar banner clicked',
          data: {
            'banner name': banner.title,
            'campaign name': banner.campaign ? banner.campaign.title : '',
            'has delivery': analytics.hasRecipeDelivery,
          },
        })
      }
    >
      <CMSImage
        src={banner.image.src}
        alt={banner.image.altText}
        sources={banner.image.sources}
        data-testid="calendar-promotional-banner"
      />

      {countdown.display && (
        <Flex
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          right={0}
          direction="column"
          alignItems="center"
        >
          <ResponsiveText variant="heading-md" textColor={countdown.textColor}>
            {daysLeft === 1 ? 'Last day' : daysLeft}
          </ResponsiveText>
          <ResponsiveText variant="heading-xxs" textColor={countdown.textColor}>
            {daysLeft === 1 ? 'to order!' : 'days left to order!'}
          </ResponsiveText>
        </Flex>
      )}
    </CMSBannerLinkWrapper>
  )
}
