import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { ColorToken, ZIndexToken } from '../system'
import Illustration, { IllustrationTypes } from '../Illustrations/Illustrations'

import {
  WaveT1,
  WaveT2,
  WaveT3,
  WaveT4,
  WaveT5,
  WaveB1,
  WaveB2,
  WaveB3,
  WaveB4,
  WaveB5,
} from '../Waves'

interface Illustration {
  type: IllustrationTypes
  styles?: BoxProps
}

export interface WaveIllustrationWrapperProps {
  bgColor?: ColorToken
  contentMaxWidth?: string
  illustrations?: {
    topLeft?: Illustration
    topRight?: Illustration
    bottomLeft?: Illustration
    bottomRight?: Illustration
  }
  margin?: string
  waveBgColor?: ColorToken | ColorToken[]
  waveBottom?: 1 | 2 | 3 | 4 | 5 | null
  waveTop?: 1 | 2 | 3 | 4 | 5 | null
  zIndex?: ZIndexToken
}

export const WaveIllustrationWrapper: FunctionComponent<
  WaveIllustrationWrapperProps
> = ({
  waveTop = null,
  waveBottom = null,
  waveBgColor = 'white',
  bgColor,
  contentMaxWidth = '1024px',
  illustrations = null,
  zIndex = 'auto',
  margin = '0',
  children,
}) => {
  const { top: waveTopBgColor, bottom: waveBottomBgColor } =
    getWaveBgColor(waveBgColor)

  return (
    <Box as="section" margin={margin} position="relative" zIndex={zIndex}>
      {/* Top wave */}
      {waveTop && (
        <Box backgroundColor={waveTopBgColor}>
          {renderWave(waveTop, bgColor)}
        </Box>
      )}

      {/* Content */}
      <Box
        backgroundColor={bgColor}
        // Extra padding waves are not present
        pt={!waveTop ? 32 : 0}
        pb={!waveBottom ? 32 : 0}
      >
        <Box maxWidth={contentMaxWidth} mx="auto" width="100%">
          {children}
        </Box>
      </Box>

      {/* Bottom wave */}
      {waveBottom && (
        <Box backgroundColor={waveBottomBgColor}>
          {renderWave(waveBottom, bgColor, false)}
        </Box>
      )}

      {/* Illustrations */}
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        pointerEvents="none"
      >
        <Box
          position="relative"
          maxWidth={contentMaxWidth}
          mx="auto"
          height="100%"
        >
          {illustrations?.topLeft && (
            <Illustration
              position="absolute"
              left={0}
              top={0}
              transform="translateY(-50%)"
              type={illustrations.topLeft.type}
              {...(illustrations.topLeft.styles || {})}
            />
          )}
          {illustrations?.topRight && (
            <Illustration
              position="absolute"
              right={0}
              top={0}
              transform="translateY(-50%)"
              type={illustrations.topRight.type}
              {...(illustrations.topRight.styles || {})}
            />
          )}
          {illustrations?.bottomLeft && (
            <Illustration
              position="absolute"
              left={0}
              bottom={0}
              transform="translateY(50%)"
              type={illustrations.bottomLeft.type}
              {...(illustrations.bottomLeft.styles || {})}
            />
          )}
          {illustrations?.bottomRight && (
            <Illustration
              position="absolute"
              right={0}
              bottom={0}
              transform="translateY(50%)"
              type={illustrations.bottomRight.type}
              {...(illustrations.bottomRight.styles || {})}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

function renderWave(waveReference: number, color: ColorToken, isTop = true) {
  const styles = {
    color,
    position: 'relative',
    ...(isTop ? { top: 1 } : { bottom: 1 }),
  } as BoxProps

  switch (waveReference) {
    case 1:
      return isTop ? <WaveT1 {...styles} /> : <WaveB1 {...styles} />
    case 2:
      return isTop ? <WaveT2 {...styles} /> : <WaveB2 {...styles} />
    case 3:
      return isTop ? <WaveT3 {...styles} /> : <WaveB3 {...styles} />
    case 4:
      return isTop ? <WaveT4 {...styles} /> : <WaveB4 {...styles} />
    case 5:
      return isTop ? <WaveT5 {...styles} /> : <WaveB5 {...styles} />
    default:
      return null
  }
}

function getWaveBgColor(bgColor: ColorToken | ColorToken[]) {
  const colorTokens = typeof bgColor === 'string' ? [bgColor] : bgColor
  const [top, bottom] = colorTokens

  return {
    top,
    bottom: bottom || top, // Default to top color with single color
  }
}
