import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'

export const getDaysLeft = (date: ISODate): number => {
  const endDate = new Date(date).getTime()
  const todayDate = new Date().getTime()
  const mSecondsIntoDays = 1000 * 60 * 60 * 24
  const daysLeft = Math.ceil((endDate - todayDate) / mSecondsIntoDays + 1)

  return daysLeft < 0 ? 0 : daysLeft
}
