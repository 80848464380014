import React, { FC } from 'react'
import NextLink from 'next/link'
import { Box, Link } from '@mindfulchefuk/design-system'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'

export const CMSBannerLinkWrapper: FC<
  BoxProps & { href?: string; onClick?: () => void; testId?: string }
> = ({
  children,
  href,
  onClick,
  testId = 'cms-banner-link-wrapper',
  ...boxProps
}) => {
  if (!href) {
    return (
      <Box data-testid={testId} {...boxProps}>
        {children}
      </Box>
    )
  }

  return (
    <NextLink href={href} passHref>
      <Link
        display="block"
        data-testid={testId}
        as="a"
        onClick={onClick}
        target={isExternalURL(href) ? '_blank' : '_self'}
        rel="noopener noreferrer"
        {...boxProps}
      >
        {children}
      </Link>
    </NextLink>
  )
}

function isExternalURL(url: string) {
  try {
    return new URL(url).origin !== window.location.origin
  } catch {
    return false // internal URLs like "/choose-recipes"
  }
}
