import { CMSImageRaw } from '@mindfulchefuk/features/CMS/interfaces'

type CMSImage = {
  data: {
    attributes: CMSImageRaw
  }
}

export function getCMSImageSrc(img: CMSImage): string {
  if (!img?.data) return

  const {
    data: { attributes },
  } = img

  return attributes.hash + attributes.ext
}
