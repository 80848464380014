import { ColorToken } from '@mindfulchefuk/design-system'
import { useCMSPage } from '@mindfulchefuk/features/CMS/hooks/useCMSPage'
import { useDualFlowVariant } from '@mindfulchefuk/features/DualFlow/hooks'
import { useIsBBCGoodFood } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'

type ColorHash = {
  [key: string]: ColorToken
}

const seasonalColors: ColorHash = {
  spring: 'veg20',
  summer: 'veg2',
  autumn: 'veg12',
  winter: 'meat6',
}

const partnershipColors: ColorHash = {
  bbcGoodFood: 'bbc-good-food-pink',
}

const dualFlowColors: ColorHash = {
  newbie: 'veg2',
  pro: 'veg2',
}

export const useHomepageThemeEmphasisText = (): ColorToken => {
  const { data } = useCMSPage('page-homepage')
  const isBBCGoodFood = useIsBBCGoodFood()
  const { isNewbie, isPro } = useDualFlowVariant()

  // Ignore CMS for BBC good food
  if (isBBCGoodFood) return partnershipColors.bbcGoodFood

  // Ignore CMS dual flow
  if (isNewbie) return dualFlowColors.newbie
  if (isPro) return dualFlowColors.pro

  if (!data) return seasonalColors.winter

  return seasonalColors[data.theme]
}
