import React from 'react'
import {
  ColorToken,
  Text,
  TextProps,
  TypographyToken,
} from '@mindfulchefuk/design-system'
import { useHomepageThemeEmphasisText } from '@mindfulchefuk/features/Homepage/hooks/useHomepageThemeEmphasisText'
import { generateEmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/utils/generateEmphasisLinebreakText'
import { Responsive } from '@mindfulchefuk/design-system/system/types'

type Props = TextProps & {
  id?: string
  text: string
  textColor?: ColorToken
  emphasisText?: string
  emphasisVariant?: Responsive<TypographyToken>
}

export function EmphasisLinebreakText({
  id = null,
  text,
  emphasisText,
  emphasisVariant,
  textColor,
  ...textProps
}: Props) {
  const themeColor = useHomepageThemeEmphasisText()

  if (!text) return null
  if (!text.trim()) return null

  const textArray = generateEmphasisLinebreakText(text, emphasisText)

  return (
    <Text {...textProps} id={id || undefined}>
      {textArray.map(({ emphasis, text: textContent, inline }, lineIndex) => {
        const addLineBreak = !inline
        return (
          <Text
            key={textContent}
            as="span"
            data-testid={emphasis ? 'text-with-emphasis' : 'text-no-emphasis'}
            variant={emphasis ? emphasisVariant : textProps.variant}
            color={emphasis ? themeColor : 'inherit'}
            id={id ? `${id}-line-${lineIndex + 1}` : undefined}
          >
            {addLineBreak && <br />}
            {textContent}{' '}
          </Text>
        )
      })}
    </Text>
  )
}
