import { formatStringWithLinebreaks } from '@mindfulchefuk/features/CMS/utils/formatStringWithLinebreaks'

export function generateEmphasisLinebreakText(
  text: string,
  emphasisText: string
) {
  const textWithEmphasis = []
  const emphasisIndex = text.indexOf(emphasisText)

  if (emphasisIndex !== -1) {
    const beforeEmphasis = text.substring(0, emphasisIndex).trim()
    const emphasis = text.substr(emphasisIndex, emphasisText.length)
    const afterEmphasis = text
      .substring(emphasisIndex + emphasisText.length)
      .trim()

    if (beforeEmphasis) {
      textWithEmphasis.push({ emphasis: false, text: beforeEmphasis })
    }

    textWithEmphasis.push({ emphasis: true, text: emphasis })

    if (afterEmphasis) {
      textWithEmphasis.push({ emphasis: false, text: afterEmphasis })
    }
  } else {
    textWithEmphasis.push({ text, emphasis: false })
  }

  const textWithEmphasisAndLinebreaks = textWithEmphasis.reduce(
    (textArray, { text: textContent, emphasis }) => {
      const texts = formatStringWithLinebreaks(textContent)
      return textArray.concat(
        texts.map((splitText, splitTextIndex) => ({
          emphasis,
          text: splitText,
          inline: splitTextIndex === 0,
        }))
      )
    },
    []
  )

  return textWithEmphasisAndLinebreaks
}
