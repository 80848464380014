import React, { ReactElement, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from '@mindfulchefuk/design-system'
import {
  selectProductAnnouncement,
  useCMSPage,
} from '@mindfulchefuk/features/CMS/hooks/useCMSPage'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { CMSPageApiPathWithBanners } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { getCMSImageSrc } from '@mindfulchefuk/features/CMS/utils/getCMSImageSrc'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

type TProductAnnouncementModal = {
  page: CMSPageApiPathWithBanners
}

export const ProductAnnouncementModal = ({
  page,
}: TProductAnnouncementModal): ReactElement => {
  const router = useRouter()
  const [showProductAnnouncement, setShowProductAnnouncement] = useState<
    boolean | null
  >(null)

  const { data: content } =
    useCMSPage(page, {
      select: (data) => selectProductAnnouncement(data),
    }) ?? {}

  const handleClose = () => {
    setShowProductAnnouncement(false)
    window.localStorage.setItem(
      `${content.name}_product_announcement_shown`,
      'true'
    )
  }

  const handleRedirectFromCTA = () => {
    handleClose()
    trackAnalyticsEvent({
      name: 'product announcement clicked',
    })
    router.push(content?.ctaUrl)
  }

  useEffect(() => {
    if (content) {
      const hasUserBeenShownProductAnnouncement = window.localStorage.getItem(
        `${content.name}_product_announcement_shown`
      )

      setShowProductAnnouncement(!hasUserBeenShownProductAnnouncement)
    }
  }, [content])

  useEffect(() => {
    if (content && showProductAnnouncement) {
      trackAnalyticsEvent({
        name: 'product announcement viewed',
        data: {
          'feature name': content.name,
        },
      })
    }
  }, [content, showProductAnnouncement])

  if (!showProductAnnouncement) {
    return null
  }

  return (
    <Modal
      size="medium"
      closeModal={handleClose}
      data-testid="product-announcement-modal"
    >
      <ModalHeader closeModal={handleClose}>
        <Text variant="heading-sm" color="aubergine">
          {content.title}
        </Text>
      </ModalHeader>
      <ModalBody display="flex" flexDirection="column" alignItems="center">
        <Text variant="body-md">{content.description}</Text>

        {content.media?.data && (
          <Box
            width={384}
            height={288}
            my={32}
            border="1px solid"
            borderColor="pepper-50"
            overflow="hidden"
          >
            <CMSImage src={getCMSImageSrc(content.media)} />
          </Box>
        )}

        <Button
          fullWidth
          variant="primary"
          size="medium"
          onClick={handleRedirectFromCTA}
          mt={!content?.media?.data && 16}
        >
          {content.ctaText}
        </Button>

        {content.closeButton && (
          <Button
            variant="secondary"
            fullWidth
            size="medium"
            mt={16}
            onClick={handleClose}
          >
            Close
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}
