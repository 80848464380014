import React, { ReactElement } from 'react'
import { ColorToken, Flex } from '@mindfulchefuk/design-system'

import { Svg } from '@mindfulchefuk/design-system/Svg/Svg'
import { Icon, IconProps } from './Icon'

function Background(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 40 38"
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      z={0}
      {...props}
    >
      <path
        d="M39.864 19.302c-.67-5.54-3.08-10.254-7.102-14.14C30.46 2.94 27.768 1.365 24.66.522c-2.012-.546-4.057-.608-6.123-.435-3.378.283-6.598 1.13-9.555 2.79C3.585 5.906.296 10.331.02 16.665a19.19 19.19 0 00.016 2.041c.017.272.042.543.072.814l.015.128a16.853 16.853 0 00.165 1.053l.005.029c.038.192.079.383.124.574.014.122.03.244.048.365.037.247.087.492.157.731a3.906 3.906 0 00.168.457l.003.052c.002.04.003.08.008.118l.01.055c.785 2.876 2.098 5.487 3.977 7.809.117.144.232.29.35.435.09.111.182.221.275.33.272.314.562.611.907.856.01.022.019.046.027.07a.41.41 0 00.024.056.14.14 0 00.026.033c1.406 1.338 2.944 2.491 4.726 3.296.078.05.157.094.238.136.243.125.5.216.761.299.469.224.95.412 1.448.548.02.006.041.013.062.018.174.046.35.085.528.117.03.02.06.035.091.047.146.058.308.047.457.093.723.2 1.459.319 2.202.375.247.02.496.031.744.037 2.156.049 4.302-.073 6.423-.494 1.169-.232 2.342-.447 3.457-.887a19.748 19.748 0 005.104-2.28c5.45-3.404 8.002-8.254 7.226-14.674"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Svg>
  )
}

/**
 * @deprecated Use `design-system/Roundel/Roundel` instead
 */
export default function Roundel({
  width,
  height,
  size,
  mx,
  background,
  color = 'white',
  ...props
}: IconProps): ReactElement {
  return (
    <Flex
      width={width}
      height={height}
      size={size}
      mx={mx}
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding={10}
    >
      <Icon {...props} color={color} z={1} />
      <Background {...props} color={background as ColorToken} />
    </Flex>
  )
}
